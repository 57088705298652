<script>
// import { MonthPickerInput } from 'vue-month-picker'
import axios from 'axios';
// import { ref } from 'vue';

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(
        newValue
      );
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(",");
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(
          document.documentElement
        ).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default {
  data(){
    return {
      monthFilter: null,
      paramExtra: "ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt"),
      chart: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              barHeight: "100%",
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: "bottom",
              },
            },
          },
          colors: getChartColorsArray(
            '["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info", "--vz-warning", "--vz-danger", "--vz-dark", "--vz-primary", "--vz-success", "--vz-secondary"]'
          ),
          dataLabels: {
            enabled: true,
            textAnchor: "start",
            style: {
              colors: ["#fff"],
            },
            formatter: function (val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
            },
            offsetX: 0,
            dropShadow: {
              enabled: false,
            },
          },
          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
          title: {
            text: "Agent Average Response",
            align: "center",
            floating: true,
            style: {
              fontWeight: 600,
            },
          },
          subtitle: {
            text: "Grafik rata-rata agent response",
            align: "center",
          },
          tooltip: {
            theme: "dark",
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function () {
                  return "";
                },
              },
            },
          },
          noData: {
            text: "Tidak ada data",
            style: {
              fontSize: "1.5rem",
            }
          }
        },
      }

    }
  },
  methods: {
    async showDate () 
    {
      this.chart.series[0].data = [];
      this.chart.chartOptions.noData.text = "Mohon tunggu, sedang mengkalkulasi data";
      let url = process.env.VUE_APP_URL_API+"agent-api/filterFirstResponse?dateFilter="+this.monthFilter+"&"+this.paramExtra;
      const filterresponse = await axios.get(url);
      
      let seriesData = [];

      if(filterresponse.status == 200){
        let data = filterresponse.data.Data
        data.forEach(item => {
          seriesData.push(item.AgentResponTime)
        });
      }
      this.setChart(seriesData)
    },
    async getChartDatas()
    {
      this.chart.chartOptions.noData.text = "Mohon tunggu, sedang mengkalkulasi data";
      let url = process.env.VUE_APP_URL_API+"agent-api/getFirstResponse?ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt");
      const getFirstResponse = await axios.get(url);

      let seriesData = [];

      if(getFirstResponse.status == 200){
        let data = getFirstResponse.data
        data.Data.forEach((item, index) => {
          seriesData[index] = item.AgentResponTime
        })
      }
      this.setChart(seriesData);
    },
    setChart(seriesData)
    {
      this.chart.series[0].data = seriesData
    }
  },
  async mounted() {
    console.log("Mounted on agent-first-response component");
    await this.getChartDatas();
  },
  async beforeMount() {
    let url = process.env.VUE_APP_URL_API+"agent-api/getFirstResponse?ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt");
    const getFirstResponse = await axios.get(url);

    if(getFirstResponse.status == 200){
      let data = getFirstResponse.data
      data.Data.forEach((item) => {
        this.chart.chartOptions.xaxis.categories.push(item.Name);
      })
    }
  },
  components: {
    // MonthPickerInput
  }
};
</script>

<template>
  <div class="card card-height-100">
    <div class="card-header align-items-center d-flex">
      <h4 class="card-title mb-0 flex-grow-1">Agent Average Response (in minutes)</h4>
      <div class="flex-shrink-0">
        <div class="row">
          <div class="col-5">
            <h5 class="card-title mt-2 flex-grow-1">Filter Month</h5>
          </div>
          <div class="col-7">
            <span class="text-muted">
              <input v-model="monthFilter" v-on:change="showDate" type="month" class="form-control" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- end card header -->
    <div class="card-body px-0">
      <apexchart
        class="apex-charts"
        height="350"
        dir="ltr"
        :series="this.chart.series"
        :options="this.chart.chartOptions"
      ></apexchart>
    </div>
  </div>
  <!-- end card -->
</template>
